import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useCallback, useState } from 'react';
// hooks
import { useLocalStorage } from '../hooks/uselocalstorage';
// utils
//
import { localStorageAvailable, localStorageGetItem } from '../utils/storage-available';

import { SettingsContext } from './context';
// ----------------------------------------------------------------------

export function SettingsProvider({ children, defaultSettings }) {
    const [openDrawer, setOpenDrawer] = useState(false);

    const [settings, setSettings] = useLocalStorage('settings', defaultSettings);

    const onUpdate = useCallback(
        (name, value) => {
            setSettings((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        },
        [setSettings]
    );

    // Reset
    const onReset = useCallback(() => {
        setSettings(defaultSettings);
    }, [defaultSettings, setSettings]);

    // Drawer
    const onToggleDrawer = useCallback(() => {
        setOpenDrawer((prev) => !prev);
    }, []);

    const onCloseDrawer = useCallback(() => {
        setOpenDrawer(false);
    }, []);

    const canReset = !isEqual(settings, defaultSettings);

    const memoizedValue = useMemo(
        () => ({
            ...settings,
            onUpdate,
            // Reset
            canReset,
            onReset,
            // Drawer
            open: openDrawer,
            onToggle: onToggleDrawer,
            onClose: onCloseDrawer,
        }),
        [
            onReset,
            onUpdate,
            settings,
            canReset,
            openDrawer,
            onCloseDrawer,
            onToggleDrawer,
        ]
    );

    return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
}

SettingsProvider.propTypes = {
    children: PropTypes.node,
    defaultSettings: PropTypes.object,
};

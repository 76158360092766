import '../public/css/bootstrap.min.css';
import '../public/css/animate.min.css';
import '../public/css/boxicons.min.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import '../public/css/style.css';
import '../public/css/responsive.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import Loader from '../components/Shared/Loader';
import GoTop from '../components/Shared/GoTop';
import Router from 'next/router';
import Matomo from '../components/Common/Matomo';
import { SettingsProvider } from '../context/settings';
import '../locales/i18n';


export default class MyApp extends App {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.timerHandle = setTimeout(() => this.setState({ loading: false }), 500);
        Router.events.on('routeChangeComplete', () => {
            window.scroll({
                top: 1,
                left: 0,
                behavior: 'smooth'
            });
        });
    }

    componentWillUnmount() {
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
            this.timerHandle = null;
        }
    }

    render() {
        const { Component, pageProps } = this.props;

        return (
            <>
                <Head>
                    <title>Simplify Data</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                    <meta name="description" content="Simplify Data - Ihr starker IT-Partner mit innovativen und modernen Lösungen für jede Situation!" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="og:title" content="Simplify Data - Ihr starker IT-Partner mit innovativen und modernen Lösungen für jede Situation!" />
                    <meta property="og:image" content="https://www.simplify-data.de/images/logoog.png" />
                </Head>

                <Matomo />

                <SettingsProvider>
                    <Component {...pageProps} />
                </SettingsProvider>

                <Loader loading={this.state.loading} />
                <GoTop scrollStepInPx="100" delayInMs="10.50" />
            </>
        );
    }
}
